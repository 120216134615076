<template>
  <div class="terms">
    <h1 class="title">退货条件及规则</h1>
    <p class="titleInfo">Return Conditions And Rules</p>
    <p class="s1">
      1、商品可以坚持原有质量、功用;买家应当确保退回商品和相关配(附)件(如吊牌、阐明书、三包卡等)齐全，并坚持原有质量、功用，无受损、受污、刮开防伪、产生激活(受权)等情形，无难以恢恢复状的外观类运用痕迹、不合理的个人数据运用痕迹。
    </p>
    <p class="s1">
      The commodity can adhere to the original quality, function; The buyer
      should ensure that the return of goods and related (attached) pieces (such
      as tags, statements, three packages of cards, etc.) complete, and adhere
      to the original quality, function, no damage, contamination, scratch off
      anti-counterfeiting, activation (authorized) and other circumstances, no
      difficult to recover the appearance of the use of traces, unreasonable
      personal data use traces.
    </p>
    <p class="s1">
      2、买家基于查验需求而翻开商品包装，或者为确认商品的质量、功用而停止合理的调试不影响商品的完好。
    </p>
    <p class="s1">
      Buyers based on the inspection needs to open the packaging of goods, or to
      confirm the quality of goods, function and stop reasonable debugging does
      not affect the good condition of goods.
    </p>
    <p class="s1">
      对超出查验和确认商品质量、功用需求而运用商品，招致商品价值贬损较大的，视为商品不完好。
    </p>
    <p class="s1">
      If a commodity is used beyond the requirements of checking and confirming
      the quality and function of the commodity, resulting in a great loss of
      the value of the commodity, the commodity shall be deemed to be in bad
      condition..
    </p>
    <p class="s1">
      3、商品包装作为商品的必要组成局部，其缺损情况易招致商品价值贬损，故退回时应确保商品包装完好。
    </p>
    <p class="s1">
      As a necessary part of the commodity, the defect of the commodity is easy
      to lead to the derogation of the value of the commodity, so it should be
      returned to ensure that the commodity packaging is intact.
    </p>
    <p class="s1">
      局部具有特定价值或功用的特殊商品包装，可依卖家事前显著明示的包装维护请求处置。
    </p>
    <p class="s1">
      Special COMMODITY packages that are PARTIALLY OF specific value or
      function MAY BE disposed OF IN ACCORDANCE WITH THE SELLER's prior and
      conspicuously EXPRESSED packaging MAINTENANCE REQUEST.
    </p>
    <p class="s1">
      4、买卖双方线下达成退货协议的，买家应在系统规则的退货时间内停止退货。
    </p>
    <p class="s1">
      If the buyer and the seller reach an agreement on the return of goods
      offline, the buyer shall stop the return of goods within the return time
      stipulated in the system.
    </p>
    <p class="s1">
      买家因超时未退货，对同一问题或维权缘由再次主张请求退货的，应自行和卖家协商分歧，否则，Vancheen有权不予处置
    </p>
    <p class="s1">
      If the buyer fails to return the goods due to the time limit and claims to
      return the goods again for the same issue or the reason of rights
      protection, it shall negotiate with the seller on its own; otherwise,
      Vancheen shall have the right to refuse to dispose of the goods.
    </p>
    <p class="s1">
      5、买家依据本Vancheen规则退货后，应及时在Vancheen平台系统内填写承运单号或告知卖家。
    </p>
    <p class="s1">
      After returning goods in accordance with these Vancheen Rules, the buyer
      shall fill in the waybill number or inform the seller in the Vancheen
      platform system in time.
    </p>
    <p class="s1">
      6、卖家违背“退货、换货标准”致使买家无法完成退换货或商品已不适合退货，买卖支持退款。
    </p>
    <p class="s1">
      The seller violates the "return, exchange standards" resulting in buyers
      unable to complete the return or commodity is not suitable for return,
      business support refund.
    </p>
    <p class="s1">
      7、买家违背“退货、换货标准”致使卖家未收到退货或拒签的，由买家承担商品损毁或灭失的风险。
    </p>
    <p class="s1">
      Buyers violate the "return, exchange standards" cause the seller did not
      receive returns or refused to sign, the buyer bear the risk of commodity
      damage or loss.
    </p>

    <h1 class="title">退货政策</h1>
    <p class="titleInfo">Returns Policy</p>
    <p class="s1">
      从 Vancheen 发货的商品（包括 Vancheen
      Warehouse）可在交货后3天内退货，但有一些例外情况：食品商品不支持退货。如涉及包装质量问题，可求助平台客服。
    </p>

    <p class="s1">
      Items shipped from Vancheen, can be returned within 3 days of delivery,
      with some exceptions: Food products that can't be returned. If the
      packaging quality problem is involved, you can ask for help from customer
      service of Vancheen platform.
    </p>

    <p class="s1">
      如果产品类型未列出或未说明其他退货时间范围，则适用我们的标准3天退货政策。
    </p>

    <p class="s1">
      If the product type isn't listed or doesn't state another returns time
      frame, our standard 3-day return policy applies.
    </p>

    <p class="s1">
      如确认需要退货，退货前请先联系平台客服，同意退货后需遵从退货指引及流程。退货产生的国际运输费用以平台客服协商后为准。
    </p>

    <p class="s1">
      If you confirm that you need to return the goods, please contact customer
      service before returning the goods. After agreeing to return the goods,
      please follow the return guidelines and procedures. The international
      transportation fee incurred by the return shall be subject to the
      consultation of the customer service.
    </p>

    <div class="agreeBox" @click="gotoShipping">
      <div class="agree">{{ $t.meta.shippingRules }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReturnRules",
  data() {
    return {};
  },
  created() {
    const { headerHide } = this.$route.query;
    if (headerHide) {
      this.headerHide = JSON.parse(headerHide);
      if (this.headerHide) {
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      }
    }
  },
  methods: {
    gotoShipping() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "goodsTerms/ShippingRules",
        name: "ShippingRules",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.terms {
  width: 90%;
  margin: 0 auto;
  * {
    font-size: 11px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
}
.agreeBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 3% 0;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  .agree {
    width: 80%;
    margin: auto;
    border-radius: 3rem;
    padding: 0.8rem 0;
    text-align: center;
    background: linear-gradient(left, #71d283, #01aaa3);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
